<template>
  <div>
    <b-row cols="4">
      <b-col>
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="search"
            :placeholder="$t('Mã giao dịch/ Nội dung')"
          />
        </b-input-group>
      </b-col>

      <b-col cols="2">
        <b-form-datepicker
          v-model="dateFrom"
          local="vn"
          :placeholder="$t('Từ ngày')"
          selected-variant="primary"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        />
      </b-col>

      <b-col cols="2">
        <b-form-datepicker
          v-model="dateTo"
          local="vn"
          :placeholder="$t('Đến ngày')"
          selected-variant="primary"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        />
      </b-col>

      <b-col cols="2">
        <b-form-group>
          <v-select
            v-model="type"
            :placeholder="$t('Loại thanh toán')"
            label="text"
            :options="typeOptions"
            :reduce="item => item.value"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <v-select
            v-model="payGate"
            :placeholder="$t('Cổng TT')"
            label="text"
            :options="payGateOptions"
            :reduce="gate => gate.value"
          />
        </b-form-group>
      </b-col>

      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="fetchHistory(true)"
        >
          {{ $t('Tìm kiếm') }}
        </b-button>
      </b-col>

      <b-col cols="auto">
        <b-button
          variant="outline-primary"
          @click="exportOrder"
        >
          {{ $t('Export') }}
        </b-button>
      </b-col>
    </b-row>

    <b-table
      small
      :fields="fields"
      :items="items"
      responsive
      class="mt-2"
      bordered
    >
      <!-- A virtual column -->
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(transactionId)="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template>
      <template #cell(port)="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template>
      <template #cell(updatedAt)="data">
        <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
      </template>
      <template #cell(amountBeforeChange)="data">
        <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
      </template>
      <template #cell(amountChange)="data">
        <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
      </template>
      <template #cell(amountAfterChange)="data">
        <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
      </template>
    </b-table>

    <b-row>
      <b-col class="d-flex justify-content-md-end">
        <b-pagination
          v-model="pagination.current_page"
          :total-rows="pagination.total"
          :per-page="pagination.per_page"
          :first-text="$t('Trang đầu')"
          :prev-text="$t('Trang trước')"
          :next-text="$t('Trang tiếp')"
          :last-text="$t('Trang cuối')"
          @change="changePage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import { formatDateTimeDb } from '@/libs/timezone'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { useMasterFilter, useTransactionHistory } from '@/views/order-management/useOrder'
// eslint-disable-next-line import/no-cycle
import { useI18n } from '@core/utils/utils'

export default {
  name: 'BalanceHistory',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    vSelect,
  },
  setup() {
    const { t } = useI18n()
    const {
      merchantName,
      merchantNameOptions,
      dateFrom,
      dateTo,
      payGate,
      payGateOptions,
      orderStatus,
      orderStatusOptions,
      fetchMerchantNameOptions,
    } = useMasterFilter()

    const {
      search,
      fields,
      items,
      pagination,
      fetchBalanceHistory,
    } = useTransactionHistory()

    const type = ref(null)
    const typeOptions = ref([
      { value: 'recharge', text: t('Nạp tiền') },
      { value: 'withdraw_money', text: t('Rút tiền') },
      { value: 'transfer', text: t('Chuyển tiền') },
      { value: 'add_money', text: t('Cộng tiền') },
      { value: 'sub_money', text: t('Trừ tiền') },
    ])

    const fetchHistory = (isSearch = false) => {
      fetchBalanceHistory({
        search: search.value,
        page: isSearch ? 1 : pagination.value.current_page,
        date_from: dateFrom.value,
        date_to: dateTo.value,
        type: payGate.value,
        status: orderStatus.value,
        trans_type: type.value,
      }).then(response => {
        items.value = response.data
        pagination.value = response.meta.pagination
      })
    }

    const changePage = page => {
      pagination.value.current_page = page
      fetchHistory()
    }

    onMounted(() => {
      fetchHistory()
      payGateOptions.value = [
        { value: 'payin', text: 'Payin' },
        { value: 'payout', text: 'Payout' },
      ]
    })

    return {
      merchantName,
      merchantNameOptions,
      dateFrom,
      dateTo,
      payGate,
      payGateOptions,
      type,
      typeOptions,
      search,
      orderStatus,
      orderStatusOptions,

      fields,
      items,
      pagination,

      changePage,
      fetchHistory,
      fetchMerchantNameOptions,
      formatDateTimeDb,
    }
  },
  methods: {
    exportOrder() {
      this.$http.get('/export/order-history', {
        responseType: 'blob',
        params: {
          search: this.search,
          date_from: this.dateFrom,
          date_to: this.dateTo,
          type: this.payGate,
          status: this.orderStatus,
          trans_type: this.type,
        },
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/xls' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'export.xlsx'
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
}

.vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
</style>
